import React from "react";
import { BoxProps, Flex, Text } from "@chakra-ui/react";
import { IconHidden } from "src/components/icons";

export interface IEmptyCard extends BoxProps {
    title: string;
    description: string;
}

export const EmptyCard = ({ title, description, ...boxProps }: IEmptyCard) => {
    return (
        <React.StrictMode>
            <Flex {...boxProps} w="100%" borderRadius="8px" boxShadow="xs" flexFlow="column" alignItems="center" px={3} py={2}>
                <IconHidden boxSize={[10, "80px"]} />
                <Text fontSize="sm" fontWeight="bold" pt={2} textAlign="center">
                    {title}
                </Text>

                <Text fontSize="sm" textAlign="center">
                    {description}
                </Text>
            </Flex>
        </React.StrictMode>
    );
};
