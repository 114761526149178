import { HStack } from "@chakra-ui/react";
import { ModuleSecondaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";

export interface ITwoColumnTextModuleProps {
    title: string;
    titleAsH3?: boolean;
    firstColumnDescription: string;
    secondColumnDescription: string;
}

export const TwoColumnTextModule = ({ title, titleAsH3, firstColumnDescription, secondColumnDescription }: ITwoColumnTextModuleProps) => {
    return (
        <Container>
            <ModuleSecondaryHeadline titleAsH2={!titleAsH3} mb={[2, 2, 3]}>
                {title}
            </ModuleSecondaryHeadline>
            <HStack gap={[3, 3, 4]} alignItems="flex-start" flexWrap={["wrap", "wrap", "nowrap"]}>
                <SimpleRichText fontSize="lg" w="100%" flexGrow={0} text={firstColumnDescription} />
                <SimpleRichText fontSize="lg" w="100%" flexGrow={0} text={secondColumnDescription} />
            </HStack>
        </Container>
    );
};
