import { Box, Flex, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowButton } from "src/components/common/buttons/arrow-button";
import { PrimaryHeadline } from "src/components/common/module-headlines";
import { SCROLL_SNAP_PROPS } from "src/components/common/scroll-snap";
import { IconFilledStar } from "src/components/icons";
import { Container, CONTAINER_PX } from "src/components/layout/container";
import { SCROLL_DIRECTION, useAutoScrollingList } from "src/hooks/dom/use-auto-scrolling-list";
import { numberToPx } from "src/utils/number-format-utils";
import { IQuoteCardProps, QuoteCard } from "./quote-card";
import { QuoteGrayBackground, QUOTE_FLEX_BASIS, QUOTE_PADDING_LEFT } from "./single-quote-module";

const CARD_WIDTH = 300;
const CARD_INITIAL_PADDING_LEFT = QUOTE_PADDING_LEFT;
const CARD_PADDING_X_BIG = 12;

export interface IMultiQuoteModuleProps {
    title: string;
    titleAsH1: boolean;
    quoteCards: IQuoteCardProps[];
    showRatingStars?: boolean;
}

export const MultiQuoteModule = ({ title, titleAsH1, quoteCards, showRatingStars = false }: IMultiQuoteModuleProps) => {
    const { t } = useTranslation("common");

    const { canScrollPrev, canScrollNext, scrollContainerRef, collectItemRefs, handleScroll } = useAutoScrollingList({
        disableOnBreakpoint: [true, true, false],
    });

    return (
        <Container px={[0, 5, 3, 3]} pr={[0, 0, 0, 0]} pos="relative">
            <QuoteGrayBackground />
            <Flex w="100%" flexDir={["column", "row"]} align="center">
                {title && (
                    <PrimaryHeadline px={[CONTAINER_PX[0], 0]} mr={[0, 3, 5]} textAlign={["center", "left"]} titleAsH1={titleAsH1}>
                        {title}
                    </PrimaryHeadline>
                )}
                <Box maxW="100%" overflow="hidden" pos="relative" flexShrink={0} flexBasis={QUOTE_FLEX_BASIS}>
                    <Flex w="100%" align="start" mt={3} display={["none", "none", "flex"]} pos="absolute">
                        <Box pl={CARD_INITIAL_PADDING_LEFT} mr={numberToPx(CARD_PADDING_X_BIG * 2)}>
                            <HStack w={numberToPx(CARD_WIDTH)} spacing={2} opacity={showRatingStars ? 1 : 0}>
                                <IconFilledStar boxSize="32px" color="gray.200" />
                                <IconFilledStar boxSize="32px" color="gray.200" />
                                <IconFilledStar boxSize="32px" color="gray.200" />
                                <IconFilledStar boxSize="32px" color="gray.200" />
                                <IconFilledStar boxSize="32px" color="gray.200" />
                            </HStack>
                        </Box>
                        <HStack
                            spacing={2}
                            w={["auto", "auto", "auto", `${CARD_WIDTH}px`]}
                            justify={["flex-start", "flex-start", "flex-start", "flex-end"]}
                            display={["none", "none", "flex", quoteCards.length > 2 ? "flex" : "none"]}
                        >
                            <ArrowButton
                                transform="rotate(180deg)"
                                isDisabled={!canScrollPrev}
                                aria-label={t("nav-left")}
                                onClick={() => handleScroll(SCROLL_DIRECTION.PREV)}
                            />
                            <ArrowButton
                                aria-label={t("nav-right")}
                                isDisabled={!canScrollNext}
                                onClick={() => handleScroll(SCROLL_DIRECTION.NEXT)}
                            />
                        </HStack>
                    </Flex>
                    <Flex ref={scrollContainerRef} overflowX="auto" pt={[4, 10]} pb={[7, 10]} sx={SCROLL_SNAP_PROPS}>
                        {quoteCards.map((quoteCard, index) => (
                            <Box
                                key={index}
                                pl={index === 0 ? [CARD_INITIAL_PADDING_LEFT] : [1, `${CARD_PADDING_X_BIG}px`]}
                                pr={[1, `${CARD_PADDING_X_BIG}px`]}
                                sx={{
                                    scrollSnapAlign: ["center", "start"],
                                }}
                            >
                                <QuoteCard
                                    ref={node => collectItemRefs({ node, index })}
                                    {...quoteCard}
                                    minW={`${CARD_WIDTH}px`}
                                    w={`${CARD_WIDTH}px`}
                                    h="100%"
                                    py={3}
                                    px={[2, 3]}
                                />
                            </Box>
                        ))}
                        <Box h="1px" minW={`${CARD_PADDING_X_BIG}px`} />
                    </Flex>
                </Box>
            </Flex>
        </Container>
    );
};
