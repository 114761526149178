import { useQuery } from "src/hooks/react-query/use-query";
import { ITrader } from "src/types/common";

export type TUnderlying = {
    annualizedPerformance: number;
    annualizedVolatility: number;
    imageUrl: string;
    isin: string;
    shortDescription: string;
};

interface IPortfolioScenarios {
    annualizedPerformance: number;
    annualizedPerformanceChange: number;
    annualizedVolatility: number;
    annualizedVolatilityChange: number;
    percentageOfWikifolio: number;
}

interface IWikifolio {
    id: string;
    shortDescription: string;
    symbol: string;
    trader: ITrader;
}

export interface IWikifolioComplement {
    portfolioScenarios: IPortfolioScenarios[];
    // Dev-Note: this is for tracking
    selectionMethod: string;
    wikifolio: IWikifolio;
}

export interface IUnderlyingWikifolioComplementQueryResponse {
    underlying: TUnderlying;
    wikifolioComplements: IWikifolioComplement[];
}

export function useUnderlyingWikifolioComplementQuery(underlyingisin: string) {
    return useQuery<IUnderlyingWikifolioComplementQueryResponse>(`/v2/underlyings/${underlyingisin}/wikifolio-complement`, {
        enabled: Boolean(underlyingisin),
    });
}
