import { useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

interface IWikifolioEtfSliderProps {
    defaultValue?: number;
    numberOfSteps?: number;
    min?: number;
    max?: number;
    minSelectableValue?: number;
    maxSelectableValue?: number;
    isDisabled: boolean;
    onChange?: (value: number) => void;
    isPercentagesInverted?: boolean;
}

const WIKIFOLIO_ETF_SLIDER_DISTRIBUTION_INFO_TEST_ID = "wikifolio-etf-slider-distribution-info";
const WIKIFOLIO_ETF_SLIDER_ETF_LABEL_TEST_ID = "wikifolio-etf-slider-etf-label";
const WIKIFOLIO_ETF_SLIDER_ETF_SHARE_TEST_ID = "wikifolio-etf-slider-etf-share";
const WIKIFOLIO_ETF_SLIDER_WIKIFOLIO_LABEL_TEST_ID = "wikifolio-etf-slider-wikifolio-label";
const WIKIFOLIO_ETF_SLIDER_WIKIFOLIO_SHARE_TEST_ID = "wikifolio-etf-slider-wikifolio-share";

export const WikifolioEtfSlider = ({
    defaultValue = 50,
    numberOfSteps = 10,
    min = 0,
    max = 100,
    minSelectableValue = 10,
    maxSelectableValue = 90,
    onChange,
    isDisabled,
    isPercentagesInverted = false,
}: IWikifolioEtfSliderProps) => {
    const { t } = useTranslation("mix-etf-with-wikifolio-module");
    const [sliderValue, setSliderValue] = useState(defaultValue);

    const handleOnChange = (value: number) => {
        // Flip the value only for logic
        const actualValue = isPercentagesInverted ? max - value + min : value;

        if (actualValue >= minSelectableValue && actualValue <= maxSelectableValue) {
            onChange?.(actualValue);
            // Keep the original slider value for display purposes
            setSliderValue(value);
        }
    };

    return (
        <Box w="100%" p={2} pt={10} rounded="8px" borderRadius="8px" boxShadow="base" bg="white">
            <Slider
                isDisabled={isDisabled}
                step={numberOfSteps}
                min={min}
                max={max}
                value={sliderValue}
                onChange={handleOnChange}
                aria-label={t("labels.slider-description")}
            >
                <SliderTrack h="6px" bg="green.600">
                    <SliderFilledTrack h="6px" bg="lilac.600" />
                </SliderTrack>

                {Array.from({ length: numberOfSteps }, (_, i) => (
                    <SliderMark mt="-3px" key={i} value={(i + 1) * numberOfSteps} width="2px" h="6px" bg="white" />
                ))}

                <SliderMark transform="translateX(-50%)" top={-8} value={sliderValue}>
                    <Flex
                        color="white"
                        gap="1px"
                        borderWidth="1px"
                        borderColor="gray.100"
                        bg="gray.100"
                        borderRadius="8px"
                        overflow="hidden"
                        fontSize="md"
                        data-test-id={WIKIFOLIO_ETF_SLIDER_DISTRIBUTION_INFO_TEST_ID}
                    >
                        <Box w={["60px", 10]} pt="2px" pb="4px" textAlign="center" bg="lilac.600">
                            <Text fontWeight="bold" data-test-id={WIKIFOLIO_ETF_SLIDER_ETF_SHARE_TEST_ID}>
                                {sliderValue} %
                            </Text>
                            <Text fontSize="xs" data-test-id={WIKIFOLIO_ETF_SLIDER_ETF_LABEL_TEST_ID}>
                                {t("labels.etf")}
                            </Text>
                        </Box>
                        <Box w={["60px", 10]} pt="2px" pb="4px" textAlign="center" bg="green.600">
                            <Text fontWeight="bold" data-test-id={WIKIFOLIO_ETF_SLIDER_WIKIFOLIO_SHARE_TEST_ID}>
                                {100 - sliderValue} %
                            </Text>
                            <Text fontSize="xs" data-test-id={WIKIFOLIO_ETF_SLIDER_WIKIFOLIO_LABEL_TEST_ID}>
                                {t("labels.wikifolio")}
                            </Text>
                        </Box>
                    </Flex>
                </SliderMark>
                <SliderThumb
                    _focusVisible={{ outline: "none" }}
                    _active={{ transform: "translateY(-50%) scale(1.1)" }}
                    borderWidth="3px"
                    borderColor="gray.700"
                    backgroundColor="white"
                    boxSize={4}
                />
            </Slider>
        </Box>
    );
};
