import { BoxProps, Flex, Skeleton } from "@chakra-ui/react";
import { ChartLoadingState } from "src/components/common/chart-shared-components/chart-loading-state";

interface IChartNoDataStateProps extends BoxProps {}

export const BubbleChartLoadingSkeleton = ({ ...boxProps }: IChartNoDataStateProps) => {
    return (
        <Flex width="100%" flexFlow={["column", "column", "row", "row"]} {...boxProps}>
            <ChartLoadingState position="static" height={["400px", "495px", "597px"]} maxW="551px" numberOfLines={9} />

            <Flex display={["none", "flex"]} pl={[0, 0, 7, 7]} pt={2} gap={1} flexFlow={[null, null, "column", "column"]} flexWrap="wrap">
                <Skeleton h="24px" w={["201px", "201px", "201px", "296px"]} />
                <Skeleton h="24px" w="129px" />
                <Skeleton h="24px" w="201px" />
                <Skeleton h="24px" w="211px" />
                <Skeleton h="24px" w="205px" />
                <Skeleton h="24px" w="190px" />
                <Skeleton h="24px" w="114px" />
                <Skeleton h="24px" w="102px" />
            </Flex>
        </Flex>
    );
};
