import { Badge, Flex, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfLinkGhost } from "src/components/base/wf-link";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { ILeaderBoardTrader, ILeaderBoardWikifolio } from "src/components/modules/leader-board-module/leader-board-module.types";
import { TableBorderShadowShell } from "src/components/table-scrollable/components/table-border-shadow-shell";
import { TableStickyCellAnimateContainer } from "src/components/table-scrollable/components/table-sticky-cell-animate-container";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";

interface ILeaderBoardTraderCellProps {
    trader: ILeaderBoardTrader;
    wikifolio: ILeaderBoardWikifolio;
}

export const LeaderBoardTraderCell = ({ trader, wikifolio }: ILeaderBoardTraderCellProps) => {
    const { t } = useTranslation("leader-board-module");
    const getUserName = useGetUserName();
    const { buildTraderProfileLink, buildWikifolioLink } = useLinkBuilder();

    const traderProfileLink = buildTraderProfileLink(trader.nickName);
    const wikifolioLink = buildWikifolioLink(wikifolio?.symbol);
    const traderName = getUserName(trader);

    return (
        <TableBorderShadowShell>
            <TableStickyCellAnimateContainer>
                <HStack marginRight="auto" spacing={1} overflow="hidden" order={0}>
                    <TraderAvatar size="sm" zIndex={1} trader={trader} href={traderProfileLink} />
                    <Flex flexDir="column" flexGrow={0} overflow="hidden">
                        <HStack gap={1}>
                            <WfLinkGhost overflow="hidden" display="inline-flex" alignItems="center" href={wikifolioLink}>
                                <Text minW="42px" as="span" overflow="hidden" textOverflow="ellipsis">
                                    {wikifolio.shortDescription}
                                </Text>
                            </WfLinkGhost>
                            {trader.isCurrentUserWikifolio && (
                                <Badge display={["none", "none", "unset"]} flexShrink={0} size="xs">
                                    {t("table.my-wikifolio")}
                                </Badge>
                            )}
                        </HStack>
                        <Flex overflow="hidden">
                            <WfLinkGhost fontWeight="normal" fontSize="sm" href={traderProfileLink} overflow="hidden" textOverflow="ellipsis">
                                {traderName}
                            </WfLinkGhost>
                        </Flex>
                        <Text textOverflow="ellipsis" overflow="hidden">
                            {trader.organisationName ?? ""}
                        </Text>
                    </Flex>
                </HStack>
            </TableStickyCellAnimateContainer>
        </TableBorderShadowShell>
    );
};
