import React from "react";
import { HStack, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowButton } from "src/components/common/buttons/arrow-button";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { SCROLL_SNAP_PROPS } from "src/components/common/scroll-snap";
import { Container, CONTAINER_PX_SM_MD, CONTAINER_PX_SM_MD_NEGATIVE } from "src/components/layout/container";
import { SCROLL_DIRECTION, useAutoScrollingList } from "src/hooks/dom/use-auto-scrolling-list";
import { ColumnContentMemo } from "./components/column-content";
import { IMultiListTopWikifoliosModuleProps } from "./multi-list-wikifolios-module.types";

export const MultiListTopWikifoliosModule = ({
    bestRecentlySeenWikifoliosColumn,
    topWatchlistedWikifoliosColumn,
    topWikifoliosColumn,
    title,
}: IMultiListTopWikifoliosModuleProps) => {
    const { t } = useTranslation("common");
    const { canScrollPrev, canScrollNext, scrollContainerRef, collectItemRefs, handleScroll } = useAutoScrollingList({
        disableOnBreakpoint: [true, true, false, true],
    });

    const wikifolioColumns = [topWikifoliosColumn, topWatchlistedWikifoliosColumn, bestRecentlySeenWikifoliosColumn].filter(Boolean);

    if (wikifolioColumns.length === 0) {
        return null;
    }

    return (
        <React.StrictMode>
            <Container>
                <ModulePrimaryHeadline mb={[2, 3, 3, 3]}>{title}</ModulePrimaryHeadline>
                <Stack
                    ref={scrollContainerRef}
                    spacing={[2, 2, 2, 3]}
                    direction="row"
                    overflowX={["auto", "auto", "auto", "visible"]}
                    pl={[...CONTAINER_PX_SM_MD, ...CONTAINER_PX_SM_MD, 0]}
                    pr={[2, 2, 2, 0]}
                    ml={[...CONTAINER_PX_SM_MD_NEGATIVE, ...CONTAINER_PX_SM_MD_NEGATIVE, 0]}
                    mr={[-2, -2, -2, 0]}
                    py={5}
                    my={-5}
                    sx={SCROLL_SNAP_PROPS}
                >
                    {wikifolioColumns.map((column, index) => {
                        if (!column) {
                            return null;
                        }

                        return (
                            <VStack
                                data-index={index}
                                key={index}
                                spacing={1}
                                ref={node => collectItemRefs({ node, index })}
                                w={["312px", "312px", "358px"]}
                                flexShrink={0}
                                scrollSnapAlign="center"
                            >
                                <ColumnContentMemo key={index} column={column} />
                            </VStack>
                        );
                    })}
                </Stack>
                <HStack mt={3} spacing={2} display={["none", "none", "flex", "none"]} justify="right">
                    <ArrowButton
                        opacity={!canScrollPrev ? 0.6 : 1}
                        transform="rotate(180deg)"
                        aria-label={t("nav-left")}
                        isDisabled={!canScrollPrev}
                        onClick={() => handleScroll(SCROLL_DIRECTION.PREV)}
                    />
                    <ArrowButton
                        opacity={!canScrollNext ? 0.6 : 1}
                        aria-label={t("nav-right")}
                        isDisabled={!canScrollNext}
                        onClick={() => handleScroll(SCROLL_DIRECTION.NEXT)}
                    />
                </HStack>
            </Container>
        </React.StrictMode>
    );
};
