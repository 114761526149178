import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useUnderlyingWikifolioComplementQuery } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { EmptyState } from "src/components/common/empty-state";
import { MixEtfWikifolioRangeContextProvider } from "src/components/modules/mix-etf-with-wikifolio-module/context/mix-etf-wikifolio-range-context";
import { Step1Section } from "./step-1-section";
import { Step2Section } from "./step-2-section";
import { Step3Section } from "./step-3-section";

interface IStepsWrapper {
    step1Label: string;
    step2Label: string;
    step3Label: string;
    searchPlaceholder: string;
}

const UNDERLYING_ISIN_URL_PARAM = "underlyingisin";
const MIX_ETF_EMPTY_STATE_TEST_ID = "mix-etf-empty-state";

export const StepsWrapper = ({ step1Label, step2Label, step3Label, searchPlaceholder }: IStepsWrapper) => {
    const router = useRouter();
    const { t } = useTranslation("mix-etf-with-wikifolio-module");
    const { query } = router;

    const [selectedUnderlyingIsin, setSelectedUnderlyingIsin] = useState<null | string>(query.underlyingisin as string);
    const { data, isLoading, isError } = useUnderlyingWikifolioComplementQuery(selectedUnderlyingIsin as string);

    const onSelectedUnderlyingIsin = (isin: string | null) => {
        setSelectedUnderlyingIsin(isin);
        router.replace(
            {
                pathname: window.location.pathname,
                query: `${UNDERLYING_ISIN_URL_PARAM}=${isin}`,
            },
            undefined,
            { shallow: true }
        );
    };

    const onResetIsin = () => {
        setSelectedUnderlyingIsin(null);
        router.replace(
            {
                pathname: window.location.pathname,
                query: null,
            },
            undefined,
            { shallow: true }
        );
    };

    if (isError) {
        onResetIsin();
    }

    const existsWikifolioComplements = data?.wikifolioComplements && data.wikifolioComplements.length !== 0;

    return (
        <Box>
            <Step1Section
                isLoading={isLoading}
                sectionTitle={step1Label}
                underlying={data?.underlying}
                selectedUnderlyingIsin={selectedUnderlyingIsin}
                onSelectedUnderlyingIsin={onSelectedUnderlyingIsin}
                onReset={onResetIsin}
                searchPlaceholder={searchPlaceholder}
                pt={[3, 5]}
            />

            {(selectedUnderlyingIsin && existsWikifolioComplements) || isLoading ? (
                <MixEtfWikifolioRangeContextProvider>
                    <Step2Section isLoading={isLoading} sectionTitle={step2Label} pt={[3, 5]} />
                    <Step3Section
                        isLoading={isLoading}
                        underlying={data?.underlying}
                        wikifolioComplements={data?.wikifolioComplements}
                        sectionTitle={step3Label}
                        pt={[3, 5]}
                    />
                </MixEtfWikifolioRangeContextProvider>
            ) : null}

            {selectedUnderlyingIsin && !existsWikifolioComplements && !isLoading ? (
                <EmptyState
                    mt={5}
                    title={t("titles.not-found-suitable-etf")}
                    description={t("description.not-found-suitable-etf")}
                    boxShadow={undefined}
                    descriptionProps={{ maxWidth: "522px" }}
                    data-test-id={MIX_ETF_EMPTY_STATE_TEST_ID}
                />
            ) : null}
        </Box>
    );
};
