import React from "react";
import { VStack } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { WfHeading } from "src/components/base/wf-heading";
import { WikifolioInfoCard } from "src/components/modules/components/wikifolio-info-card/wikifolio-info-card";
import { IWikifolioColumn } from "src/components/modules/multi-list-top-wikifolios-module/multi-list-wikifolios-module.types";
import { EmptyCard } from "./empty-card";

interface IColumnContent {
    column: IWikifolioColumn;
}
const ColumnContent = ({ column }: IColumnContent) => {
    const link = column.wikifolios.length === 0 ? column.emptyStateLink : column.link;
    return (
        <>
            {column?.title && (
                <WfHeading as="h2" fontSize="xl" w="100%" noOfLines={1} wordBreak="break-all">
                    {column.title}
                </WfHeading>
            )}
            {column.wikifolios.length === 0 ? (
                <EmptyCard title={column.emptyStateTitle} minH="239" description={column.emptyStateDescription} />
            ) : (
                <VStack w="100%" spacing={1}>
                    {column.wikifolios.map(wikifolio => (
                        <WikifolioInfoCard key={wikifolio.symbol} w="100%" {...wikifolio} />
                    ))}
                </VStack>
            )}
            {link && (
                <ArrowLink mt={1} alignSelf="end" href={link.url} target={link.target}>
                    {link.name}
                </ArrowLink>
            )}
        </>
    );
};

export const ColumnContentMemo = React.memo(ColumnContent);
