import { Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { makeLeaderBoardColumns } from "src/components/modules/leader-board-module/components/leader-board-columns";
import { LeaderBoardDropdown } from "src/components/modules/leader-board-module/components/leader-board-dropdown";
import { LeaderBoardEmptyState } from "src/components/modules/leader-board-module/components/leader-board-empty-state";
import { LEADER_BOARD_PAGE_SIZE_OPTIONS, useLeaderBoardTable } from "src/components/modules/leader-board-module/hooks/use-leader-board-table";
import { ILeaderBoardOrganisation, ILeaderBoardRankingValue } from "src/components/modules/leader-board-module/leader-board-module.types";
import { TablePagination } from "src/components/table-scrollable/components/table-pagination";
import { TableScrollable } from "src/components/table-scrollable/table-scrollable";

interface ILeaderBoardTableComponentProps {
    challengePeriod: string;
    organisations: ILeaderBoardOrganisation[];
    rankings: ILeaderBoardRankingValue[];
}

export const LeaderBoardTableComponent = ({ organisations, challengePeriod, rankings }: ILeaderBoardTableComponentProps) => {
    const { t } = useTranslation("leader-board-module");
    const {
        data,
        totalResultsCount,
        isLoading,
        loadingData,
        pagination,
        setPagination,
        sorting,
        setSorting,
        selectedOrganisation,
        setSelectedOrganisation,
    } = useLeaderBoardTable({ challengePeriod, rankings });

    const columns = makeLeaderBoardColumns({
        t,
        rankings,
        isLoading,
    });

    return !isLoading && (!data || data.length === 0) && selectedOrganisation == "null" ? (
        <LeaderBoardEmptyState />
    ) : (
        <>
            <Box maxWidth="327px" mb={1}>
                <LeaderBoardDropdown
                    isDisabled={false}
                    value={selectedOrganisation}
                    options={organisations}
                    onChange={setSelectedOrganisation}
                    placeholder={t("dropdown.placeholder")}
                    emptyText={t("dropdown.empty")}
                    allText={t("dropdown.all")}
                />
            </Box>
            <TableScrollable
                data={isLoading ? loadingData : data}
                columns={columns}
                pagination={pagination}
                onPaginationChange={setPagination}
                manualPagination={true}
                manualSorting={true}
                onSortingChange={setSorting}
                sorting={sorting}
                rowCount={totalResultsCount}
                rowStyle={{
                    "&:hover ": {
                        backgroundColor: "gray.50",
                    },
                }}
                paginationRender={table => (
                    <TablePagination
                        pageSizeOptions={LEADER_BOARD_PAGE_SIZE_OPTIONS}
                        pageSize={table.getState().pagination.pageSize}
                        itemsCount={totalResultsCount}
                        onPageSizeChange={table.setPageSize}
                        currentPageIndex={table.getState().pagination.pageIndex}
                        pageIndexes={table.getPageOptions()}
                        onPageSelect={table.setPageIndex}
                        onPreviousClick={table.previousPage}
                        onNextClick={table.nextPage}
                        canGoPreviousPage={table.getCanPreviousPage()}
                        canGoNextPage={table.getCanNextPage()}
                        isLoading={!totalResultsCount && isLoading}
                        itemsLabel={t("table.items")}
                    />
                )}
            />
        </>
    );
};
