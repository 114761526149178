import { ReactNode } from "react";
import { FigCaption, Figure } from "src/components/base";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfHeading } from "src/components/base/wf-heading";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { IImage, ILink } from "src/types/common";
import { EnumHorizontalAlignment } from "src/types/common-enum";

interface ILinkWrapperProps {
    link?: ILink;
    children: ReactNode;
}

const LinkWrapper = ({ link, children }: ILinkWrapperProps) => {
    if (link) {
        return (
            <WfLinkUnstyled href={link.url} target={link.target}>
                {children}
            </WfLinkUnstyled>
        );
    }
    return children;
};

export interface IImageModuleProps {
    title?: string;
    titleAsH2?: boolean;
    titleAlignment?: EnumHorizontalAlignment;
    image: IImage;
    tabImage?: IImage;
    mobImage?: IImage;
    caption?: string;
    largeCaptionFontSize?: boolean;
    link?: ILink;
}

export const ImageModule = ({
    title,
    titleAsH2,
    titleAlignment,
    image,
    tabImage,
    mobImage,
    caption,
    largeCaptionFontSize,
    link,
}: IImageModuleProps) => {
    return (
        <Container>
            {title && (
                <WfHeading
                    as={titleAsH2 ? "h2" : "h3"}
                    fontSize={titleAsH2 ? ["3xl", "3xl", "4xl"] : ["2xl", "2xl", "3xl"]}
                    mb={[3, 3, 4]}
                    textAlign={titleAlignment === EnumHorizontalAlignment.Center ? "center" : "left"}
                >
                    {title}
                </WfHeading>
            )}
            <Figure>
                <LinkWrapper link={link}>
                    <ResponsiveImage
                        display={[mobImage || tabImage ? "none" : "block", tabImage ? "none" : "block", "block"]}
                        baseUrl={image.url}
                        alt={image.alt}
                        borderRadius="4px"
                    />
                </LinkWrapper>
                {tabImage && (
                    <ResponsiveImage
                        display={[mobImage ? "none" : "block", "block", "none"]}
                        baseUrl={tabImage.url}
                        alt={tabImage.alt}
                        borderRadius="4px"
                    />
                )}
                {mobImage && <ResponsiveImage display={["block", "none"]} baseUrl={mobImage.url} alt={mobImage.alt} borderRadius="4px" />}
                {caption && (
                    <FigCaption mt={2}>
                        <SimpleRichText text={caption} fontSize={largeCaptionFontSize ? ["md", "lg", "lg"] : "xs"} />
                    </FigCaption>
                )}
            </Figure>
        </Container>
    );
};
