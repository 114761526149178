// eslint-disable-next-line no-restricted-imports
import { Box, Td, Th, Text, Skeleton, HStack, SkeletonCircle, VStack } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "next-i18next";
import { LeaderBoardFigureValue } from "src/components/modules/leader-board-module/components/leader-board-figure-value";
import { LeaderBoardTableHeader } from "src/components/modules/leader-board-module/components/leader-board-table-header";
import { LeaderBoardTableValue } from "src/components/modules/leader-board-module/components/leader-board-table-value";
import { LeaderBoardTraderCell } from "src/components/modules/leader-board-module/components/leader-board-trader-cell";
import { LeaderBoardFigureValueType } from "src/components/modules/leader-board-module/leader-board-module.consts";
import { ILeaderBoardRankingValue, ILeaderBoardTableData } from "src/components/modules/leader-board-module/leader-board-module.types";
import { TABLE_HEADER_ELEMENT_HEIGHT } from "src/components/table-scrollable/components/constants";
import { TableRowCell } from "src/components/table-scrollable/components/table-row-cell";

const CURRENT_USER_HIGHLIGHT_COLOR = "green.50";

interface IMakeLeaderBoardColumnsProps {
    rankings: ILeaderBoardRankingValue[];
    isLoading: boolean;
    t: TFunction;
}

export const makeLeaderBoardColumns = ({
    t,
    rankings,
    isLoading,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IMakeLeaderBoardColumnsProps): ColumnDef<ILeaderBoardTableData, any>[] => {
    const columnHelper = createColumnHelper<ILeaderBoardTableData>();

    const columns = [
        columnHelper.accessor("trader", {
            header: ({ header }) => {
                return (
                    <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        height={TABLE_HEADER_ELEMENT_HEIGHT}
                        border="none"
                        padding={0}
                        backgroundColor="white"
                        zIndex={1}
                        position="sticky"
                        left="0"
                        _hover={{ color: "gray.600" }}
                    >
                        <TableRowCell header={header} height="100%" paddingLeft={[0, 0, 2]}>
                            <Box
                                position="absolute"
                                h="100%"
                                top={0}
                                left={0}
                                w="100%"
                                maxW={["229px", "229px", "unset"]}
                                borderRightWidth={["2px", "2px", 0, 0]}
                                borderColor="gray.100"
                            />
                        </TableRowCell>
                    </Th>
                );
            },
            cell: info => {
                return (
                    <Td
                        height={["64px", "64px", "64px", "100%"]}
                        borderWidth={0}
                        padding={0}
                        position="sticky"
                        left="0"
                        backgroundColor={info.getValue().isCurrentUserWikifolio ? CURRENT_USER_HIGHLIGHT_COLOR : "white"}
                        zIndex={1}
                        key={info.cell.id}
                    >
                        {isLoading ? (
                            <HStack py={[1, 1, 2]} pl={[0, 0, 2]} pr={[1, 1, 2]} spacing={1} align="flex-start">
                                <SkeletonCircle flexShrink="0" size="5" />
                                <VStack width="100%" spacing="2px" align="flex-start">
                                    <Skeleton borderRadius="4px" height="20px" width="100%" maxWidth="200px" />
                                    <Skeleton borderRadius="4px" height="20px" width="100%" maxWidth="160px" />
                                    <Skeleton borderRadius="4px" height="20px" width="100%" />
                                </VStack>
                            </HStack>
                        ) : (
                            <LeaderBoardTraderCell trader={info.cell.getValue()} wikifolio={info.row.original.wikifolio} />
                        )}
                    </Td>
                );
            },
            enableSorting: false,
            meta: { customTd: true, customTh: true },
        }),
        ...rankings.map((ranking, index) =>
            columnHelper.accessor(item => item.wikifolio.rankings, {
                id: ranking.id,
                header: ({ header }) => (
                    <TableRowCell header={header} pr={1}>
                        <LeaderBoardTableHeader tooltip={ranking.tooltip?.content}>
                            {ranking.id.includes(LeaderBoardFigureValueType.RankingScoreBVHChallenge) ? (
                                <>
                                    <Text fontSize="sm" as="span">
                                        {t("table.headers.place")}
                                    </Text>
                                    <Text textAlign="left" fontWeight="normal" fontSize="sm">
                                        {t("table.headers.score")}
                                    </Text>
                                </>
                            ) : (
                                <Text fontSize="sm" as="span" whiteSpace="pre" mr={0.5}>
                                    {ranking.label}
                                </Text>
                            )}
                        </LeaderBoardTableHeader>
                    </TableRowCell>
                ),
                cell: info => {
                    const rankingData = info.renderValue();
                    return rankingData && rankingData[index] ? (
                        <LeaderBoardTableValue traderColumn={info.row.getValue("trader")} highlightColor={CURRENT_USER_HIGHLIGHT_COLOR} pr={2}>
                            {isLoading ? (
                                <Skeleton borderRadius="4px" height="20px" width="100%" />
                            ) : (
                                <LeaderBoardFigureValue
                                    value={rankingData[index].value}
                                    type={rankingData[index].id}
                                    index={rankingData[index].index}
                                />
                            )}
                        </LeaderBoardTableValue>
                    ) : null;
                },
            })
        ),
    ];

    return columns;
};
