import { useRef, useEffect, useCallback, useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import debounce from "lodash.debounce";

export const SCROLL_DIRECTION = {
    PREV: "prev",
    NEXT: "next",
} as const;

export type TScrollDirection = (typeof SCROLL_DIRECTION)[keyof typeof SCROLL_DIRECTION];

/**
 * Custom hook for managing horizontal scrolling behavior in a list of elements.
 * This hook provides functionality to:
 * - Track whether the container can scroll to the previous or next item
 * - Detect if the container has overflow content
 * - Handle smooth scrolling to the next or previous item
 * - Attach and manage scroll event listeners with debouncing
 * - Disable scrolling functionality based on breakpoints
 */

interface IAutoScrollingListParams {
    /**
     * Array of boolean values that control when scrolling should be disabled
     * based on specific breakpoints (mobile, tablet, desktop, etc.).
     * Each value corresponds to a breakpoint in ascending order.
     * @example [true, false, false] - disabled on mobile, enabled on tablet and desktop
     */
    disableOnBreakpoint?: boolean[];
}
export const useAutoScrollingList = ({ disableOnBreakpoint = [false] }: IAutoScrollingListParams = {}) => {
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(true);
    const [isOverflow, setIsOverflow] = useState(false);

    const itemRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const isDisabled = useBreakpointValue(disableOnBreakpoint);

    const collectItemRefs = useCallback(({ node, index }: { node: HTMLDivElement | null; index: number }) => {
        if (node && !itemRefs.current.has(index)) {
            itemRefs.current.set(index, node);
        }
    }, []);

    const updateButtonsState = useCallback(() => {
        if (!scrollContainerRef.current || isDisabled) {
            return;
        }

        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        setCanScrollPrev(scrollLeft > 0);
        setCanScrollNext(scrollLeft + clientWidth < scrollWidth);
        setIsOverflow(scrollWidth > clientWidth);
    }, [isDisabled]);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            const debouncedUpdate = debounce(updateButtonsState, 50);
            container.addEventListener("scroll", debouncedUpdate);
            updateButtonsState();

            return () => container.removeEventListener("scroll", debouncedUpdate);
        }
    }, [updateButtonsState]);

    const handleScroll = useCallback(
        (direction: TScrollDirection): void => {
            if (!scrollContainerRef.current || isDisabled) {
                return;
            }

            const container = scrollContainerRef.current;
            const items = Array.from(itemRefs.current.values()).filter(Boolean);

            const visibleItemIndex = items.findIndex(item => item && item.getBoundingClientRect().left >= container.getBoundingClientRect().left);

            const targetIndex =
                direction === SCROLL_DIRECTION.NEXT ? Math.min(visibleItemIndex + 1, items.length - 1) : Math.max(visibleItemIndex - 1, 0);

            const targetItem = items[targetIndex];
            if (targetItem) {
                const containerStyle = window.getComputedStyle(container);
                const padding = parseInt(direction === SCROLL_DIRECTION.NEXT ? containerStyle.paddingRight : containerStyle.paddingLeft, 10);

                container.scrollTo({
                    left: targetItem.offsetLeft - container.offsetLeft - padding,
                    behavior: "smooth",
                });
            }
        },
        [isDisabled]
    );

    return {
        scrollContainerRef,
        itemRefs,
        canScrollPrev,
        canScrollNext,
        isOverflow,
        handleScroll,
        collectItemRefs,
    };
};
