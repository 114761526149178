import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { LeaderBoardFigureValueType } from "src/components/modules/leader-board-module/leader-board-module.consts";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { getPerformanceColor } from "src/utils/color-util";

interface ILeaderBoardFigureValueProps {
    value: number;
    index: number;
    type: string;
}

const HERFINDAHL_COLOR_THRESHOLD = 0.3;
export const LeaderBoardFigureValue = ({ value, type, index }: ILeaderBoardFigureValueProps) => {
    const { t } = useTranslation("leader-board-module");
    const { formatPerformance, formatPercent, formatNumber } = useNumberFormatter();

    if (value === null || index === null) {
        return <Text>{"-"}</Text>;
    }

    if (type.includes(LeaderBoardFigureValueType.RankingScoreBVHChallenge)) {
        return (
            <Box>
                <Text as="p" fontWeight="bold">
                    {t("table.values.place", { 0: index })}
                </Text>
                <Text as="p" fontWeight="normal">
                    {t("table.values.score", { 0: formatNumber(value, 1) })}
                </Text>
            </Box>
        );
    }

    let valueToDisplay = "-";
    let color: string | undefined;
    switch (true) {
        case type.includes(LeaderBoardFigureValueType.PerformanceBVHChallenge):
            valueToDisplay = formatPerformance(value, 1);
            color = getPerformanceColor(valueToDisplay);
            break;
        case type.includes(LeaderBoardFigureValueType.MaxDrawdownBVHChallenge):
            valueToDisplay = formatPercent(value, 1);
            break;
        case type.includes(LeaderBoardFigureValueType.RiskWeightBVHChallenge):
            valueToDisplay = formatNumber(value, 2);
            break;
        case type.includes(LeaderBoardFigureValueType.HerfindahlBVHChallenge):
            valueToDisplay = formatNumber(value, 2);
            if (value > HERFINDAHL_COLOR_THRESHOLD) {
                color = "red.600";
            }
            break;
    }

    return <Text color={color}>{valueToDisplay}</Text>;
};
