import { Box, BoxProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { IWikifolioComplement, TUnderlying } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { HorizontalScrollSnap } from "src/components/common/horizontal-scroll-snap-cards/horizontal-scroll-snap";
import { CARDS_WIDTH } from "src/components/common/horizontal-scroll-snap-cards/horizontal-scroll-snap-cards";
import { ScrollSnapItem } from "src/components/common/scroll-snap";
import { MixEtfWithWikifolioCard } from "./components/mix-etf-with-wikifolio-card/mix-etf-with-wikifolio-card";
import { MixEtfWithWikifolioCardSkeleton } from "./components/mix-etf-with-wikifolio-card/mix-etf-with-wikifolio-card-skeleton";

interface IStep3Section extends BoxProps {
    sectionTitle: string;
    isLoading: boolean;
    wikifolioComplements?: IWikifolioComplement[];
    underlying?: TUnderlying;
}

const MIX_ETF_STEP_3_SECTION_TEST_ID = "mix-etf-step-3-section";

export const Step3Section = ({ sectionTitle, wikifolioComplements, underlying, isLoading, ...boxProps }: IStep3Section) => {
    return (
        <Box as="section" {...boxProps} data-test-id={MIX_ETF_STEP_3_SECTION_TEST_ID}>
            <Text fontSize="lg" fontWeight="bold" as="h3" pb={2}>
                {sectionTitle}
            </Text>

            {wikifolioComplements?.length && underlying ? (
                <HorizontalScrollSnap>
                    {wikifolioComplements.map((complement, index) => (
                        <ScrollSnapItem key={complement.wikifolio.id} pr={index === 2 ? [2, 2, 0] : undefined}>
                            <MixEtfWithWikifolioCard underlying={underlying} complement={complement} width={CARDS_WIDTH} />
                        </ScrollSnapItem>
                    ))}
                </HorizontalScrollSnap>
            ) : null}

            {isLoading ? (
                <HorizontalScrollSnap>
                    {[1, 2, 3].map(index => (
                        <ScrollSnapItem key={index} pr={index === 2 ? [2, 2, 0] : undefined}>
                            <MixEtfWithWikifolioCardSkeleton width={CARDS_WIDTH} />
                        </ScrollSnapItem>
                    ))}
                </HorizontalScrollSnap>
            ) : null}
        </Box>
    );
};
