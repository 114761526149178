import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { WfHeading } from "src/components/base/wf-heading";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { LeaderBoardTableComponent } from "src/components/modules/leader-board-module/components/leader-board-table";
import { ILeaderBoardChallenge, ILeaderBoardOrganisation } from "src/components/modules/leader-board-module/leader-board-module.types";

export interface ILeaderBoardModuleProps {
    title: string;
    subtitle: string;
    organisations: ILeaderBoardOrganisation[];
    challengePeriods: ILeaderBoardChallenge[];
}

export const LeaderBoardModule = ({ title, subtitle, organisations, challengePeriods }: ILeaderBoardModuleProps) => {
    return (
        <Container>
            <WfHeading as="h2" fontSize="4xl">
                {title}
            </WfHeading>
            <SimpleRichText fontSize="lg" mt={2} mb={7} text={subtitle} />
            <Tabs variant="pills" mt={2} isLazy defaultIndex={challengePeriods.findIndex(period => period.isActive)}>
                <TabList border="none">
                    {challengePeriods.map(challenge => (
                        <Tab key={challenge.tagId}>{challenge.label}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {challengePeriods.map(challenge => (
                        <TabPanel key={challenge.tagId}>
                            <LeaderBoardTableComponent
                                rankings={challenge.rankings}
                                challengePeriod={challenge.tagId}
                                organisations={organisations}
                            />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Container>
    );
};
