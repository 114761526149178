import { Box, BoxProps, useToken } from "@chakra-ui/react";
import { FullSizeFlex } from "src/components/base";

interface IChartLoadingStateProps extends BoxProps {
    numberOfLines?: number;
}

export const ChartLoadingState = ({ children, numberOfLines = 4, ...boxProps }: IChartLoadingStateProps) => {
    const [gray200] = useToken("colors", ["gray.200"]);

    return (
        <FullSizeFlex direction="column" justify="space-around" pl={5} bg="white" {...boxProps}>
            {[...Array.from({ length: numberOfLines })].map((_, index) => (
                <Box
                    key={index}
                    backgroundImage={`linear-gradient(to right, ${gray200} 20%, white 0%)`}
                    backgroundPosition="bottom"
                    backgroundSize="10px 2px"
                    backgroundRepeat="repeat-x"
                    h="3px"
                />
            ))}
            {children}
        </FullSizeFlex>
    );
};
