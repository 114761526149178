import { VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfHeading } from "src/components/base/wf-heading";
import { IconEmptyWatchlist } from "src/components/icons";

export const LeaderBoardEmptyState = () => {
    const { t } = useTranslation("leader-board-module");
    return (
        <VStack pt={4} gap={1} width="100%" justifyContent="center">
            <IconEmptyWatchlist width="165px" height="122px" />
            <WfHeading fontSize="md" as="h2" mt={3}>
                {t("table.empty")}
            </WfHeading>
        </VStack>
    );
};
