import { Box, Text } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { IBubbleChartProps, TChartData } from "src/components/common/bubble-chart/types";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Container } from "src/components/layout/container";
import { BubbleChartLoadingSkeleton } from "./loading-skeleton/bubble-chart-loading-skeleton";

export interface IBubbleChartModuleProps {
    title: string;
    description: string;
    chartData: TChartData;
}

export const BubbleChartModule = ({ title, description, chartData }: IBubbleChartModuleProps) => {
    const { t } = useTranslation("common");

    const BubbleChart = dynamic<IBubbleChartProps>(
        () => import("src/components/common/bubble-chart/bubble-chart").then(component => component.BubbleChart),
        {
            loading: () => <BubbleChartLoadingSkeleton pt={11} pl={6} />,
            ssr: false,
        }
    );

    return (
        <Container>
            <Box>
                <ModulePrimaryHeadline pb={1}>{title}</ModulePrimaryHeadline>
                <Text>{description}</Text>
            </Box>
            <Box flex={1} alignSelf={["center", "baseline"]} display="flex" position="relative">
                <BubbleChart chartData={chartData} chartAriaLabel={t("labels.bubble-chart-module-aria-label")} />
            </Box>
        </Container>
    );
};
