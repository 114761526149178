// eslint-disable-next-line no-restricted-imports
import { Avatar, Box, BoxProps, Button, Divider, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IWikifolioComplement, TUnderlying } from "src/api/client/underlying-wikifolio-complement/underlying-wikifolio-complement.api";
import { DescriptionDefinition, DescriptionList, DescriptionTerm } from "src/components/base";
import { WfLinkGhost, WfLinkUnstyled } from "src/components/base/wf-link";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { ComplementValueDisplay, VALUE_DISPLAY_VARIANT } from "./complement-value-display";

interface IWikifolioCard extends BoxProps {
    complement: IWikifolioComplement;
    underlying: TUnderlying;
}

const MIX_ETF_WIKIFOLIO_CARD_TEST_ID = "mix-etf-wikifolio-card";
const MIX_ETF_WIKIFOLIO_CARD_TRADER_LINK_TEST_ID = "mix-etf-wikifolio-card-trader-link";
const MIX_ETF_WIKIFOLIO_CARD_WIKIFOLIO_LINK_TEST_ID = "mix-etf-wikifolio-card-wikifolio-link";
const MIX_ETF_WIKIFOLIO_CARD_INFORM_NOW_BUTTON_TEST_ID = "mix-etf-wikifolio-card-inform-now-button";

export const MixEtfWithWikifolioCard = ({ underlying, complement, ...boxProps }: IWikifolioCard) => {
    const { wikifolio } = complement;
    const { trader } = wikifolio;

    const { buildTraderProfileLink, buildWikifolioLink } = useLinkBuilder();
    const { formatVolatility, formatPerformance } = useNumberFormatter();

    const { t } = useTranslation("mix-etf-with-wikifolio-module");

    const traderProfileLink = buildTraderProfileLink(trader.nickName);
    const WikifolioLink = buildWikifolioLink(wikifolio.symbol);

    return (
        <Box flex={1} boxShadow="base" rounded="8px" p={[2, 3]} data-test-id={MIX_ETF_WIKIFOLIO_CARD_TEST_ID} {...boxProps}>
            <HStack spacing={1}>
                <Box flexShrink={0}>
                    <Avatar
                        bg="transparent"
                        variant="grayOutline"
                        borderColor="lilac.600"
                        borderWidth="2px"
                        src={underlying.imageUrl}
                        name={underlying.shortDescription}
                        loading="eager"
                    />

                    <WfLinkUnstyled href={traderProfileLink} marginLeft={-1}>
                        <Avatar
                            bg="white"
                            variant="grayOutline"
                            borderColor="green.600"
                            borderWidth="2px"
                            src={trader.traderImageUrl}
                            name={trader.displayName}
                            loading="eager"
                        />
                    </WfLinkUnstyled>
                </Box>
                <Flex flexDir="column" flexGrow={1} overflow="hidden">
                    <WfLinkGhost
                        href={WikifolioLink}
                        noOfLines={1}
                        wordBreak="break-all"
                        data-test-id={MIX_ETF_WIKIFOLIO_CARD_WIKIFOLIO_LINK_TEST_ID}
                    >
                        {wikifolio.shortDescription}
                    </WfLinkGhost>

                    <WfLinkGhost
                        href={traderProfileLink}
                        fontWeight="normal"
                        fontSize="sm"
                        noOfLines={1}
                        wordBreak="break-all"
                        data-test-id={MIX_ETF_WIKIFOLIO_CARD_TRADER_LINK_TEST_ID}
                    >
                        {trader.displayName}
                    </WfLinkGhost>
                </Flex>
            </HStack>

            <Text as="h5" fontWeight="bold" pt={3}>
                {t("titles.performance")}
            </Text>
            <Stack as={DescriptionList} direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm} fontSize="md">
                        {t("labels.etf")}
                    </Text>
                    <Text as={DescriptionDefinition} fontSize="md">
                        {formatPerformance(underlying.annualizedPerformance, 1)}
                    </Text>
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <ComplementValueDisplay
                        variant={VALUE_DISPLAY_VARIANT.annualizedPerformance}
                        complement={complement}
                        label={() => <Text as={DescriptionTerm}> {t("labels.etf-plus-wikifolio")} </Text>}
                    />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <ComplementValueDisplay
                        variant={VALUE_DISPLAY_VARIANT.annualizedPerformanceChange}
                        complement={complement}
                        label={value => (
                            <Text as={DescriptionTerm} fontWeight="bold" color={value >= 0 ? "green.600" : "red.600"}>
                                {t(value >= 0 ? "labels.performance-rise" : "labels.performance-decline")}
                            </Text>
                        )}
                    />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Text as="h5" fontWeight="bold" pt={3}>
                {t("titles.volatility")}
            </Text>
            <Stack as={DescriptionList} direction="column" justify="space-between" align="flex-start" spacing={0}>
                <Flex width="100%" py={1} justifyContent="space-between">
                    <Text as={DescriptionTerm} fontSize="md">
                        {t("labels.etf")}
                    </Text>
                    <Text as={DescriptionDefinition} fontSize="md">
                        {formatVolatility(underlying.annualizedVolatility, 1)}
                    </Text>
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <ComplementValueDisplay
                        variant={VALUE_DISPLAY_VARIANT.annualizedVolatility}
                        complement={complement}
                        label={() => <Text as={DescriptionTerm}> {t("labels.etf-plus-wikifolio")} </Text>}
                    />
                </Flex>
                <Divider borderColor="gray.100" />
                <Flex width="100%" py={1} justifyContent="space-between">
                    <ComplementValueDisplay
                        variant={VALUE_DISPLAY_VARIANT.annualizedVolatilityChange}
                        complement={complement}
                        label={value => (
                            <Text as={DescriptionTerm} fontWeight="bold" color={value <= 0 ? "green.600" : "red.600"}>
                                {t(value > 0 ? "labels.volatility-rise" : "labels.volatility-decline")}
                            </Text>
                        )}
                    />
                </Flex>
                <Divider borderColor="gray.100" />
            </Stack>

            <Button
                as={WfLinkUnstyled}
                href={WikifolioLink}
                w="full"
                colorScheme="green"
                size="sm"
                mt={3}
                data-test-id={MIX_ETF_WIKIFOLIO_CARD_INFORM_NOW_BUTTON_TEST_ID}
            >
                {t("buttons.inform-now")}
            </Button>
        </Box>
    );
};
