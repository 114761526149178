import { ReactElement } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { WfTooltip } from "src/components/base/wf-tooltip";
import { IconInfo } from "src/components/icons";

interface ILeaderBoardTableHeaderProps {
    children: ReactElement;
    tooltip?: string | null;
}

export const LeaderBoardTableHeader = ({ children, tooltip }: ILeaderBoardTableHeaderProps) => (
    <Flex alignItems="flex-start">
        <Box mr={0.5}>{children}</Box>
        <Box display={["none", "none", "unset"]}>
            <WfTooltip content={tooltip ?? ""} aria-label={tooltip} contentProps={{ maxWidth: "320px", w: "auto" }}>
                <Box>
                    <IconInfo fill="gray.100" color="grey.800" boxSize={2} />
                </Box>
            </WfTooltip>
        </Box>
    </Flex>
);
