import { ComboBox, ComboBoxItem } from "src/components/base/combo-box";
import { ILeaderBoardOrganisation } from "src/components/modules/leader-board-module/leader-board-module.types";

const comboBoxItemStyle = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inherit",
    overflow: "hidden",
};

interface IChallengeDropdownProps {
    options?: ILeaderBoardOrganisation[];
    isDisabled: boolean;
    value: string | null;
    onChange: (id: string) => void;
    placeholder: string;
    emptyText: string;
    allText: string;
}

export const LeaderBoardDropdown = ({ options, isDisabled, value, onChange, placeholder, emptyText, allText }: IChallengeDropdownProps) => (
    <ComboBox
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        inputBoxProps={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            overflow: "hidden",
            textAlign: "left",
            pr: 3,
        }}
        menuListProps={{
            maxH: 256,
            width: 327,
            zIndex: "dropdown",
        }}
    >
        {options && options.length
            ? [
                  <ComboBoxItem key={"null"} value={"null"} sx={comboBoxItemStyle} h={5}>
                      {allText}
                  </ComboBoxItem>,
                  ...options.map(option => (
                      <ComboBoxItem key={option.id} value={option.id} sx={comboBoxItemStyle} h={5}>
                          {option.name}
                      </ComboBoxItem>
                  )),
              ]
            : [
                  <ComboBoxItem key={1} value={0} sx={comboBoxItemStyle} h={5} isDisabled={true}>
                      {emptyText}
                  </ComboBoxItem>,
              ]}
    </ComboBox>
);
