import { useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import {
    ILeaderBoardRankingValue,
    ILeaderBoardResponseData,
    ILeaderBoardTableData,
} from "src/components/modules/leader-board-module/leader-board-module.types";
import { createChallengeUrl } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";

export const LEADER_BOARD_PAGE_SIZE_OPTIONS = [10, 25, 50];

interface IUseLeaderBoardProps {
    challengePeriod: string;
    rankings: ILeaderBoardRankingValue[];
}

export const useLeaderBoardTable = ({ challengePeriod, rankings }: IUseLeaderBoardProps) => {
    const [pagination, setPagination] = useState<PaginationState>({ pageSize: LEADER_BOARD_PAGE_SIZE_OPTIONS[0], pageIndex: 0 });
    const [selectedOrganisation, setSelectedOrganisation] = useState<string>("null");
    const [sorting, setSorting] = useState<SortingState>([{ id: rankings[0].id, desc: false }]);
    const [totalResultsCount, setTotalResultsCount] = useState<number>(0);

    const offset = pagination.pageIndex * pagination.pageSize;
    const { data, isLoading } = useQuery<ILeaderBoardResponseData>(createChallengeUrl(challengePeriod), {
        queryParams: new URLSearchParams([
            ...rankings.map(ranking => ["rankings", ranking.id]),
            ["organisationId", selectedOrganisation],
            ["offset", String(offset)],
            ["limit", String(pagination.pageSize)],
            ["orderBy", sorting[0].id],
            ["descending", sorting[0].desc ? "true" : "false"],
        ]),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data?.results) {
            setTotalResultsCount(data.totalResults);

            const shouldDecreasePage = offset >= data.totalResults && pagination.pageIndex > 0;
            if (shouldDecreasePage) {
                const pageIndex = Math.ceil(data.totalResults / pagination.pageSize) - 1;
                setPagination(pagination => ({ ...pagination, pageIndex: pageIndex > 0 ? pageIndex : 0 }));
            }
        }
    }, [data, pagination, offset]);

    useEffect(() => {
        setPagination(pagination => ({ ...pagination, pageIndex: 0 }));
    }, [selectedOrganisation]);

    const loadingData: ILeaderBoardTableData[] = useMemo(
        () =>
            new Array(pagination.pageSize).fill({
                trader: {
                    id: "",
                    nickname: "",
                    firstName: "",
                    lastName: "",
                    isDeleted: false,
                    isCurrentUserWikifolio: false,
                    organisationName: null,
                },
                wikifolio: {
                    shortDescription: "",
                    symbol: "",
                    rankings: rankings.map(ranking => ({
                        id: ranking.id,
                        value: 0,
                        index: 0,
                    })),
                },
            }),
        [rankings, pagination.pageSize]
    );

    return {
        data: data?.results ?? [],
        totalResultsCount,
        isLoading,
        loadingData,
        pagination,
        setPagination,
        sorting,
        setSorting,
        selectedOrganisation,
        setSelectedOrganisation,
    };
};
