import React from "react";
import { EnumContentModuleType, IAreaOverviewModule } from "src/api/server/area-overview";
import { SectionProps } from "src/components/base";
import { AutoContentModule } from "src/components/modules/auto-content-module/auto-content-module";
import { AreaOverviewModuleContainer } from "src/components/modules/base/area-overview-module-container";
import { SingleBestSellerModule } from "src/components/modules/bestseller-module/single-bestseller-module";
import { ThreeBestsellerModule } from "src/components/modules/bestseller-module/three-bestseller-module";
import { BlogTeaserModule } from "src/components/modules/blog-teaser-module";
import { BubbleChartModule } from "src/components/modules/bubble-chart-module/bubble-chart-module";
import { ChallengeFormModule } from "src/components/modules/challenge-form-module/challenge-form-module";
import { CookiebotDeclarationModule } from "src/components/modules/cookiebot-declaration-module/cookiebot-declaration-module";
import { getCtaModuleColorScheme } from "src/components/modules/cta-module/color-scheme";
import { CtaModule } from "src/components/modules/cta-module/cta-module";
import { DisclaimerModule } from "src/components/modules/disclaimer-module/disclaimer-module";
import { FaqModule } from "src/components/modules/faq-module/faq-module";
import { FeesModule } from "src/components/modules/fees-module/fees-module";
import { HeaderForHomeModule, SECTION_PB } from "src/components/modules/header-for-home-module/header-for-home-module";
import { getHeaderModuleColorScheme } from "src/components/modules/header-module/color-scheme";
import { HeaderModule } from "src/components/modules/header-module/header-module";
import { ImageModule } from "src/components/modules/image-module/image-module";
import { ImageVideoModule } from "src/components/modules/image-video-module/image-video-module";
import { JobsWidgetModule } from "src/components/modules/jobs-widget-module/jobs-widget-module";
import { LeaderBoardModule } from "src/components/modules/leader-board-module/leader-board-module";
import { ListModule } from "src/components/modules/list-module/list-module";
import { MarketoModule } from "src/components/modules/marketo-module/marketo-module";
import { MixEtfWithWikifolioModule } from "src/components/modules/mix-etf-with-wikifolio-module/mix-etf-with-wikifolio-module";
import { MockupTextModule } from "src/components/modules/mockup-text-module/mockup-text-module";
import { MultiListTopWikifoliosModule } from "src/components/modules/multi-list-top-wikifolios-module/multi-list-wikifolios-module";
import { PartnerListModule } from "src/components/modules/partner-list-module/partner-list-module";
import { PartnerLogosModule } from "src/components/modules/partner-logos-module/partner-logos-module";
import { PreviewAdvantagesModule } from "src/components/modules/preview-advantages-module/preview-advantages-module";
import { MultiQuoteModule } from "src/components/modules/quotes-module/multi-quote-module";
import { SingleQuoteModule } from "src/components/modules/quotes-module/single-quote-module";
import { RegisterModule } from "src/components/modules/register-module/register-module";
import { SearchModule } from "src/components/modules/search-module/search-module";
import { SelectedWikifoliosModule } from "src/components/modules/selected-wikifolios/selected-wikifolios-module";
import { StepsModule } from "src/components/modules/steps-module/steps-module";
import { TextImageModule } from "src/components/modules/text-image-module/text-image-module";
import { TwoColumnTextModule } from "src/components/modules/two-column-text-module/two-column-text-module";
import { UnderlyingListModule } from "src/components/modules/underlying-lists-module/underlying-list-module";
import { VotingModule } from "src/components/modules/voting-module/voting-module";
import { WallOfTextModule } from "src/components/modules/wall-of-text-module/wall-of-text-module";
import { AREA_BOTTOM_MARGIN, AREA_TOP_MARGIN } from "src/consts/styles";
import { logger } from "src/utils/logger-util";

const PARTNER_LOGOS_MODULE_TEST_ID = "partner-logos-module";
const SELECTED_WIKIFOLIOS_MODULE_TEST_ID = "selected-wikifolios-module";
const HEADER_FOR_HOME_MODULE_TEST_ID = "header-for-home-module";

const getUnknownModuleTypeErrorMessage = ({ type }: { type: never }) => `Unknown module type: ${type}`;

interface IAreaOverviewModuleProps {
    moduleProps: IAreaOverviewModule;
    sectionProps?: SectionProps;
}

export const AreaOverviewModule = ({ moduleProps, sectionProps }: IAreaOverviewModuleProps) => {
    const { hash } = moduleProps;

    switch (moduleProps.type) {
        case EnumContentModuleType.SPACING:
            return null;
        case EnumContentModuleType.BLOG_TEASER:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <BlogTeaserModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.PREVIEW_ADVANTAGES:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <PreviewAdvantagesModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.CTA:
            const { colorScheme: ctaColorScheme, ...ctaProps } = moduleProps;

            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    w="100%"
                    {...getCtaModuleColorScheme(ctaColorScheme)}
                    py={[6, 6, 8]}
                    _first={{ mt: 0 }}
                    _last={{ mb: 0 }}
                    {...sectionProps}
                >
                    <CtaModule {...ctaProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.FAQ:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <FaqModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.FEES:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <FeesModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.HEADER:
            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    w="100%"
                    {...getHeaderModuleColorScheme(moduleProps.colorScheme)}
                    _first={{ mt: 0 }}
                    {...sectionProps}
                >
                    <HeaderModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.IMAGE_VIDEO:
            const mt = !moduleProps.headline && moduleProps.showGrayBg ? [AREA_TOP_MARGIN[0], 0] : undefined;
            const mb = moduleProps.showGrayBg ? [AREA_TOP_MARGIN[0], 0] : undefined;

            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} _first={{ mt }} _last={{ mb }} {...sectionProps}>
                    <ImageVideoModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.PARTNER_LIST:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <PartnerListModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.PARTNER_LOGOS:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} data-test-id={PARTNER_LOGOS_MODULE_TEST_ID} {...sectionProps}>
                    <PartnerLogosModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.REGISTER:
            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    _first={{ mt: [AREA_TOP_MARGIN[0], AREA_TOP_MARGIN[1], 0] }}
                    _last={{ mb: [AREA_BOTTOM_MARGIN[0], AREA_BOTTOM_MARGIN[1], 0] }}
                    {...sectionProps}
                >
                    <RegisterModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.STEPS:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <StepsModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.TEXT_IMAGE:
            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    _first={{ mt: 0 }}
                    _last={{ mb: [AREA_BOTTOM_MARGIN[0], 0] }}
                    {...sectionProps}
                >
                    <TextImageModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.WALL_OF_TEXT:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} w="100%" {...sectionProps}>
                    <WallOfTextModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.LIST:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <ListModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.MOCKUP_TEXT:
            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    _first={{ mt: !moduleProps.headline ? [AREA_TOP_MARGIN[0], AREA_TOP_MARGIN[1], 0] : undefined }}
                    _last={{ mb: [AREA_BOTTOM_MARGIN[0], AREA_BOTTOM_MARGIN[1], 0] }}
                    {...sectionProps}
                >
                    <MockupTextModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.SELECTED_WIKIFOLIO:
            const { colorScheme: selectedWikifilioColorScheme = "white" } = moduleProps;
            const isBgWhite = selectedWikifilioColorScheme === "white";

            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    data-test-id={SELECTED_WIKIFOLIOS_MODULE_TEST_ID}
                    _first={{ mt: !isBgWhite ? 0 : undefined }}
                    _last={{ mb: !isBgWhite ? 0 : undefined }}
                    {...sectionProps}
                >
                    <SelectedWikifoliosModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.QUOTE:
            return moduleProps.quoteCards.length === 1 ? (
                <AreaOverviewModuleContainer sectionNavigationId={hash} _first={{ mt: [AREA_TOP_MARGIN[0], 0] }} _last={{ mb: 0 }} {...sectionProps}>
                    <SingleQuoteModule quoteCard={moduleProps.quoteCards[0]} {...moduleProps} />
                </AreaOverviewModuleContainer>
            ) : (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <MultiQuoteModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.HEADER_FOR_HOME:
            return (
                <AreaOverviewModuleContainer
                    sectionNavigationId={hash}
                    data-test-id={HEADER_FOR_HOME_MODULE_TEST_ID}
                    pb={SECTION_PB}
                    _first={{ mt: 0 }}
                    {...sectionProps}
                >
                    <HeaderForHomeModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.DISCLAIMER:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <DisclaimerModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.THREE_BESTSELLER:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <ThreeBestsellerModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.SINGLE_BESTSELLER:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <SingleBestSellerModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.JOBS_WIDGET:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <JobsWidgetModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.MARKETO:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <MarketoModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.AUTO_CONTENT:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <AutoContentModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.SEARCH:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <SearchModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.COOKIEBOT_DECLARATION:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <CookiebotDeclarationModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.VOTING:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <VotingModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.BUBBLE_CHART:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <BubbleChartModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.UNDERLYING_LISTS:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <UnderlyingListModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.MIX_ETF_WITH_WIKIFOLIO:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <MixEtfWithWikifolioModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.CHALLENGE_FORM_BVH:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <ChallengeFormModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.LEADER_BOARD:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <LeaderBoardModule {...moduleProps} />
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.MULTI_LIST:
            return (
                <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                    <React.StrictMode>
                        <MultiListTopWikifoliosModule {...moduleProps} />
                    </React.StrictMode>
                </AreaOverviewModuleContainer>
            );
        case EnumContentModuleType.IMAGE:
            return (
                <React.StrictMode>
                    <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                        <ImageModule {...moduleProps} />
                    </AreaOverviewModuleContainer>
                </React.StrictMode>
            );
        case EnumContentModuleType.TWO_COLUMN_TEXT:
            return (
                <React.StrictMode>
                    <AreaOverviewModuleContainer sectionNavigationId={hash} {...sectionProps}>
                        <TwoColumnTextModule {...moduleProps} />
                    </AreaOverviewModuleContainer>
                </React.StrictMode>
            );
        default:
            logger.error({ message: getUnknownModuleTypeErrorMessage(moduleProps) });
            return null;
    }
};
