import { Flex, FlexProps } from "@chakra-ui/react";
import { ILeaderBoardTrader } from "src/components/modules/leader-board-module/leader-board-module.types";

interface ILeaderBoardTableValueProps extends FlexProps {
    traderColumn: ILeaderBoardTrader;
    highlightColor: string;
}

export const LeaderBoardTableValue = ({ children, traderColumn, highlightColor, ...props }: ILeaderBoardTableValueProps) => {
    return (
        <Flex
            w="100%"
            h="100%"
            px={1}
            justifyContent="flex-end"
            alignItems="center"
            backgroundColor={traderColumn.isCurrentUserWikifolio ? highlightColor : "white"}
            fontWeight={traderColumn.isCurrentUserWikifolio ? "bold" : "normal"}
            {...props}
        >
            {children}
        </Flex>
    );
};
